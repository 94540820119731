$(document).ready(function () {
    var radioConsentTrackingOption2 = true;

    $('#open-modal-button').on('click',
        function () {
            openModalWindow();
        });

    $('#close-modal-button').on('click',
        function () {
            closeModalWindow();
        });

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == $('#modal-window')) {
            closeModalWindow();
        }
    }

    $('#tracking-consent-dialog-accept').on('click',
        function () {
            SendCookiesPreferences(true);
            adjustPaddingTop();
        });

    $('#tracking-consent-dialog-reject').on('click',
        function () {
            if (radioConsentTrackingOption2 === true) {
              SendCookiesPreferences(true);
            } else {
              SendCookiesPreferences(false);
            }
            adjustPaddingTop();
        });

    $('#mi-corpo-trackingconsent-label-deactivate').on('click',
        function () {
            radioConsentTrackingOption2 = false;
            $('#switchLevel2').prop('checked', false);
        });

    $('#mi-corpo-trackingconsent-label-activate').on('click',
        function () {
            radioConsentTrackingOption2 = true;
            $('#switchLevel2').prop('checked', true);
        });

});

function openModalWindow() {
    $('#modal-window').modal('show');
}

function closeModalWindow() {
    $('#modal-window').modal('hide');
}

function SendCookiesPreferences(accept) {
    TrackingConsentManager.updateUserConsent(accept);
    $('.mi-corpo-header-area').css('top', 0);

    if (accept) {
        Mi.Misc.Gclid.mainLogic();
    }
}

function adjustPaddingTop() {
    var headerHeight = $('.mi-corpo-header-area').height();
    var $miCorpoBodyArea = $('.mi-corpo-body-area');

    if ($miCorpoBodyArea.width() >= 768) {
      $miCorpoBodyArea.css('padding-top', headerHeight + 30);
    }

    if ($miCorpoBodyArea.width() <= 768) {
      $miCorpoBodyArea.css('padding-top', Math.max(headerHeight + 30, 100));
    }
}